import { useMutation } from '@tanstack/react-query';

import { client } from '../api';
import { Subtitle, Title } from '../components/Typography';
import { PrimaryButton } from '../components/Button';
import { BannerProps } from '../components/Banner';
import { PageContainer } from '../components/PageContainer';
import { AxiosError } from 'axios';
import { PatientPortalErrorResponse } from '../sharedTypes';

export function TokenExpired({ token }: { token: string }) {
  const refreshToken = useMutation<{ id: string }, AxiosError<PatientPortalErrorResponse>>({
    mutationFn: async () => {
      return client.post(`/refresh/${token}`).then((res) => res.data);
    },
    retry: false,
  });

  let messageBanner: BannerProps | undefined = undefined;

  if (refreshToken.isSuccess) {
    messageBanner = {
      variant: 'success',
      title: 'Sent to your phone',
    };
  } else if (refreshToken.isError) {
    messageBanner = {
      variant: 'error',
      title: 'Not sent. Try again.',
    };
  }

  return (
    <PageContainer banner={messageBanner}>
      <Title>Link expired</Title>
      <Subtitle>For security purposes, we need to send you a new login link.</Subtitle>
      <PrimaryButton onClick={() => refreshToken.mutate()} disabled={refreshToken.status !== 'idle'}>
        Send new link
      </PrimaryButton>
    </PageContainer>
  );
}
