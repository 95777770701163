import styled from 'styled-components';
import { BODY_MAX_WIDTH } from '../constants';
import closeModalSheet from '../assets/closeModalSheet.svg';
import { FlexColumn, FlexRow } from './Layout';
import { Subtitle, Title } from './Typography';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';

const BaseModalSheet = styled(DialogPanel)<{ $open: boolean }>`
  @media (max-width: ${BODY_MAX_WIDTH}) {
    // on mobile, we want the modal to be a full width bottom sheet
    border-radius: 1.33rem 1.33rem 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--white);
    max-height: ${({ $open }) => ($open ? '100vh' : '0')};
    transition: max-height 0.3s ease-out;
  }

  @media (min-width: ${BODY_MAX_WIDTH}) {
    // on desktop, we want the modal to be a fixed width centered on the page
    border-radius: 1.33rem;
    position: fixed;
    top: 64px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: var(--white);
    max-height: 100vh;
    max-width: ${BODY_MAX_WIDTH};
    width: 100%;
    display: ${({ $open }) => ($open ? 'block' : 'none')};
  }
`;

const ModalSheetBackdrop = styled(DialogBackdrop)<{ $open: boolean }>`
  background-color: rgba(42, 26, 62, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: ${({ $open }) => ($open ? 'block' : 'none')};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 48px;
  height: 48px;
  text-align: center;
  margin-top: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;

  &:focus-visible {
    outline: none;
    border: 1px solid var(--purple);
    box-shadow: 0 0 2px var(--purple);
  }
`;

const ModalSheetFlexColumn = styled(FlexColumn)`
  width: 100%;
`;

export const ModalSheetTitle = styled(Title)`
  margin: 0;
  text-align: left;
  margin-top: 0.8rem;
  padding-right: 48px;
  font-size 1.4rem;
`;
export const ModalSheetSubtitle = styled(Subtitle)`
  text-align: left;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
`;

interface ModalSheetProps {
  children: React.ReactNode;
  $open: boolean;
  onClose: () => void;
}

export const ModalSheet = ({ children, $open, onClose }: ModalSheetProps) => {
  return (
    <>
      <Dialog open={$open} onClose={onClose}>
        <ModalSheetBackdrop $open={$open} />
        <BaseModalSheet $open={$open}>
          <CloseButton onClick={onClose}>
            <img src={closeModalSheet} alt="Close" />
          </CloseButton>
          <FlexRow>
            <ModalSheetFlexColumn $spacing="none">{children}</ModalSheetFlexColumn>
          </FlexRow>
        </BaseModalSheet>
      </Dialog>
    </>
  );
};
