import pin from '../assets/pin.svg';
import clear from '../assets/clear.svg';

import React from 'react';
import styled from 'styled-components';

import { IconButton } from './Button';
import { DateField } from '@mui/x-date-pickers';

type Props = {
  id?: string;
  placeholder?: string;
  label: string;
  // If passed, the label will not be visually displayed.
  // Instead, it will be used as placeholder text and accessibly linked to the input.
  hideLabel?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

type SearchProps = Props & {
  onClear?: () => void;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(({ id, label, placeholder = '', ...rest }, ref) => {
  const elementId = id || `input-id-${label.replace(/\s/g, '-')}`;

  return (
    <InputContainer>
      <StyledLabel htmlFor={elementId}>{label}</StyledLabel>
      <StyledInput id={elementId} ref={ref} type="text" placeholder={placeholder} {...rest} />
    </InputContainer>
  );
});

export const SearchField = React.forwardRef<HTMLInputElement, SearchProps>(
  ({ id, label, onClear, placeholder = '', hideLabel = false, ...rest }, ref) => {
    const elementId = id || 'search-field-id';

    return (
      <InputContainer>
        {!hideLabel && <StyledLabel htmlFor={elementId}>{label}</StyledLabel>}
        <>
          <SearchFieldInput
            id={elementId}
            ref={ref}
            type="text"
            placeholder={hideLabel ? label : placeholder}
            aria-label={hideLabel ? label : undefined}
            autoComplete="off"
            {...rest}
          />
          <PinIcon src={pin} alt="" $hideLabel={hideLabel} />
          {onClear && (
            <ClearButton onClick={onClear}>
              <img src={clear} alt="clear results" />
            </ClearButton>
          )}
        </>
      </InputContainer>
    );
  },
);

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const StyledLabel = styled.label`
  color: var(--black);
  font-weight: 600;

  > div {
    margin-top: 0.25rem;
  }
`;

const StyledInput = styled.input`
  border: 1px solid var(--input-border);
  background-color: var(--white);
  border-radius: 0.66rem;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  height: 44px;
  font-size: 17px;

  &:focus-visible {
    outline: none;
    border-color: var(--purple);
    box-shadow: inset 0 0 0 1px var(--purple);
  }

  &[type='password'] {
    font: small-caption;
    font-size: 17px;
  }
`;

const SearchFieldInput = styled(StyledInput)`
  margin-top: 0.53rem;
  padding-left: 2.6rem;
  margin-bottom: 1.6rem;
`;

const PinIcon = styled.img<{ $hideLabel?: boolean }>`
  margin-top: 0.53rem;
  position: absolute;
  left: 12px;
  top: ${({ $hideLabel }) => ($hideLabel ? '0.8rem' : '36px')};
`;

const ClearButton = styled(IconButton)`
  position: absolute;
  padding: 0.75rem;
  right: 0px;
  top: 20px;
`;

export const StyledDateField = styled(DateField)`
  border: 1px solid var(--input-border);
  background-color: var(--white);

  & label {
    color: var(--black) !important;
    font-size: 1em !important;
    font-weight: 600 !important;
    font-family: 'Haffer', system-ui, Helvetica, Arial, sans-serif !important;
    letter-spacing: inherit !important;
  }

  & input {
    color: var(--black) !important;
    font-weight: 400 !important;
    font-family: 'Haffer', system-ui, Helvetica, Arial, sans-serif !important;
    font-size: 1.0625rem !important;
    line-height: 1.41 !important;
    letter-spacing: inherit !important;
    padding: 0.625rem 0.75rem 0.625rem 0.75rem !important;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-radius: 0.8rem;
    border-color: var(--input-border) !important;
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--purple) !important;
    border-width: 3px !important;
  }
`;
