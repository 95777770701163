import * as Sentry from '@sentry/react';

import cardBorder from '../assets/cardBorder.svg';
import chevronRight from '../assets/chevronRight.svg';

import imageCompression from 'browser-image-compression';
import styled, { css } from 'styled-components';
import { useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { client } from '../api';
import { PageContainer } from '../components/PageContainer';
import { Heading } from '../components/Typography';
import { SecondaryButton, LinkButton, PrimaryButton } from '../components/Button';
import { FlexColumn, FlexRow } from '../components/Layout';
import { BannerProps } from '../components/Banner';
import { AxiosError } from 'axios';

type ImageUploaderProps = {
  title: string;
  selectedImage?: File;
  setSelectedImage: (file: File) => void;
};
type InsuranceData = {
  frontImage: File;
  backImage: File;
};
type SubmitResponse = {
  status: string;
};

const INSURANCE_IMAGE_HEIGHT = '228px';
const IMG_COMPRESSION_SIZE_MB = 1;

function ImageUploader({ title, selectedImage, setSelectedImage }: ImageUploaderProps) {
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const triggerInput = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const file = event.target.files[0];
        if (!file) return;

        setUploading(true);
        if (file.size > IMG_COMPRESSION_SIZE_MB * 1024 * 1024) {
          try {
            const compressedFile = await imageCompression(file, {
              maxSizeMB: IMG_COMPRESSION_SIZE_MB,
              maxWidthOrHeight: 1080,
            });
            setSelectedImage(compressedFile);
          } catch (error) {
            console.error('Failed to compress image', error);
            Sentry.captureException(error);
            // fall-back to original image if compression fails
            setSelectedImage(file);
          }
        } else {
          setSelectedImage(file);
        }
        setUploading(false);
      }
    },
    [setSelectedImage],
  );

  const editButton = selectedImage ? (
    <EditButton onClick={triggerInput}>
      Edit <EditChevron src={chevronRight} alt="" />
    </EditButton>
  ) : undefined;
  const uploadView = uploading ? (
    <SecondaryButton disabled>Uploading...</SecondaryButton>
  ) : selectedImage ? (
    <img alt={selectedImage.name} height={INSURANCE_IMAGE_HEIGHT} src={URL.createObjectURL(selectedImage)} />
  ) : (
    <SecondaryButton onClick={triggerInput}>Add image</SecondaryButton>
  );

  return (
    <UploadSection>
      <HeaderRow>
        <Heading>{title}</Heading>
        {editButton}
      </HeaderRow>
      <UploadBox $selected={!!selectedImage}>
        {uploadView}
        <HiddenInput ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} />
      </UploadBox>
    </UploadSection>
  );
}

function getBanner(error: AxiosError<SubmitResponse> | null): BannerProps | undefined {
  if (error) {
    return {
      variant: 'error',
      title: 'Something went wrong',
    };
  }
  return undefined;
}

export default function InsuranceUpload() {
  const [frontImage, setFrontImage] = useState<File>();
  const [backImage, setBackImage] = useState<File>();
  const { state } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const enableSave = frontImage && backImage;

  const submit = useMutation<SubmitResponse, AxiosError<SubmitResponse>, InsuranceData>({
    mutationFn: async (data) => {
      const formData = new FormData();
      const { frontImage, backImage } = data;
      formData.append('front_img', frontImage, frontImage.name);
      formData.append('back_img', backImage, backImage.name);

      const response = await client.post('/update-insurance', formData);
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['insuranceList'] });
      const { source } = state || {};
      if (source) {
        navigate(source);
      } else {
        navigate('/insurance-confirmation');
      }
    },
    onError: (err) => {
      Sentry.captureException(err);
    },
  });

  return (
    <PageContainer
      pageType="modal"
      title="Add insurance"
      banner={getBanner(submit.error)}
      footer={
        enableSave ? (
          <PrimaryButton
            $fullWidth
            disabled={submit.isLoading}
            onClick={() => submit.mutate({ frontImage, backImage })}
          >
            {submit.isLoading ? 'Saving...' : 'Save'}
          </PrimaryButton>
        ) : undefined
      }
    >
      <FlexColumn>
        <HelpText>Upload images of the card with your pharmacy benefits. Look for 'BIN' or 'PCN'.</HelpText>
        <ImageUploader title="Front of card" selectedImage={frontImage} setSelectedImage={setFrontImage} />
        <ImageUploader title="Back of card" selectedImage={backImage} setSelectedImage={setBackImage} />
      </FlexColumn>
    </PageContainer>
  );
}

const HelpText = styled.div`
  margin-bottom: 1rem;
  font-weight: 400;
  color: var(--purple-dark);
`;
const UploadSection = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 2rem;
  }
`;
const HeaderRow = styled(FlexRow)`
  margin-bottom: 0.53rem;
`;
const bordered = css`
  /* Set fallback style in case border-image does not load */
  border: 1px dashed var(--input-border);
  border-image: url(${cardBorder}) 2;
`;
const UploadBox = styled.div<{ $selected?: boolean }>`
  height: ${INSURANCE_IMAGE_HEIGHT};
  width: 100%;
  margin: 0;

  ${({ $selected }) => ($selected ? '' : bordered)}
  border-radius: 1.33rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ $selected }) => ($selected ? 'var(--surface)' : 'none')};

  img {
    max-width: 100%;
    object-fit: cover;
  }
`;
const HiddenInput = styled.input`
  display: none;
`;

const EditButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
`;

const EditChevron = styled.img`
  height: 0.66rem;
  margin-left: 0.25rem;
`;
