import styled from 'styled-components';
import { FlexRow } from './Layout';
import React from 'react';

export const LabelValue: React.FC<{ label: React.ReactNode; value: React.ReactNode }> = (props) => {
  const { label, value, ...rest } = props;
  return (
    <InfoRow {...rest}>
      <div>{label}</div>
      <div>{value}</div>
    </InfoRow>
  );
};

const InfoRow = styled(FlexRow)`
  font-weight: 400;
  margin-bottom: 0.26rem;
`;
