import React from 'react';
import ReactDOM from 'react-dom/client';
import 'unfonts.css';
import './index.css';
import { App } from './App';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginPage } from './pages/Login';
import { Home } from './pages/Home';
import { MainPage } from './pages/Main';
import { PharmacySelection } from './pages/PharmacySelection';
import { Settings } from './pages/Settings';
import { FourOhFour } from './pages/404';
import { InsuranceConfirmScreen, PharmacyConfirmScreen } from './pages/ConfirmScreen';
import { LoginRequired, LoginWithToken } from './auth';
import InsuranceUpload from './pages/InsuranceUpload';
import { AddPassword } from './pages/AddPassword';
import DeliveryViaCarepoint from './pages/DeliveryViaCarepoint';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/rx',
    element: (
      <LoginRequired>
        <MainPage />
      </LoginRequired>
    ),
  },
  {
    path: '/settings',
    element: (
      <LoginRequired>
        <Settings />
      </LoginRequired>
    ),
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/rx/:id/set-pharmacy',
    element: (
      <LoginRequired>
        <PharmacySelection />
      </LoginRequired>
    ),
  },
  {
    path: '/set-pharmacy',
    element: (
      <LoginRequired authLevelRequired="token" tokenValidation="pharmacy">
        <PharmacySelection />
      </LoginRequired>
    ),
  },
  {
    path: '/pharmacy-confirmation',
    element: (
      <LoginRequired authLevelRequired="token" tokenValidation="pharmacy">
        <PharmacyConfirmScreen />
      </LoginRequired>
    ),
  },
  {
    path: '/upload-insurance',
    element: (
      <LoginRequired authLevelRequired="token" tokenValidation="insurance">
        <InsuranceUpload />
      </LoginRequired>
    ),
  },
  {
    path: '/insurance-confirmation',
    element: (
      <LoginRequired authLevelRequired="token" tokenValidation="insurance">
        <InsuranceConfirmScreen />
      </LoginRequired>
    ),
  },
  {
    path: '/check/:token',
    element: <LoginWithToken />,
  },
  {
    path: '/add-password',
    element: (
      <LoginRequired authLevelRequired="token" tokenValidation="sign_up">
        <AddPassword />
      </LoginRequired>
    ),
  },
  {
    path: '/rx/:id/carepoint-delivery',
    element: (
      <LoginRequired>
        <DeliveryViaCarepoint />
      </LoginRequired>
    )
  },
  {
    path: '*',
    element: <FourOhFour />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router} />
    </App>
  </React.StrictMode>,
);
