import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { client } from '../api';
import { PageContainer } from '../components/PageContainer';
import { RxCard } from '../components/RxCard';
import { StyledLinkPrimary } from '../components/StyledLink';
import { TANDEM_PHONE_NUMBER } from '../constants';
import { sortRxComparator } from '../utils';
import { Pharmacy, Rx } from '../sharedTypes';
import { RxPharmacyModal } from '../components/RxPharmacyModal';
import { Subtitle, Title } from '../components/Typography';

type RxHttpReturn = {
  rxs: Rx[];
};

function EmptyWallet() {
  return (
    <>
      <div>
        <Title>No prescriptions found</Title>
        <Subtitle>
          No prescriptions on file for this account. If you’re expecting a prescription from your doctor please contact
          us so we can help track it down.
        </Subtitle>
        <StyledLinkPrimary to={`sms:${TANDEM_PHONE_NUMBER}`}>Contact support</StyledLinkPrimary>
      </div>
    </>
  );
}

export function Wallet(props: { rxData: { rxs: Rx[] } }) {
  const rxs = props.rxData.rxs.sort(sortRxComparator);

  const [expandedRx, setExpandedRx] = useState<number>(0);
  const [modalPharmacy, setModalPharmacy] = useState<Pharmacy | null>(null);
  const modalRx = useMemo(() => (expandedRx > -1 ? rxs[expandedRx] : null), [expandedRx, rxs]);

  return (
    <>
      {rxs.map((rx: Rx, idx: number) => (
        <RxCard
          rx={rx}
          key={idx}
          expanded={expandedRx === idx}
          toggleExpand={() => (expandedRx === idx ? setExpandedRx(-1) : setExpandedRx(idx))}
          setModalPharmacy={setModalPharmacy}
        />
      ))}
      {modalPharmacy && modalRx ? (
        <RxPharmacyModal
          modalRx={modalRx}
          modalPharmacy={modalPharmacy}
          onClose={() => {
            setModalPharmacy(null);
          }}
        />
      ) : null}
    </>
  );
}

export function MainPage() {
  const rxListQuery = useQuery<RxHttpReturn>(['rxList'], () => {
    return client.get(`rx`).then((res) => res.data);
  });

  if (rxListQuery.isLoading) {
    return (
      <PageContainer background="cream">
        <div>Loading...</div>
      </PageContainer>
    );
  }

  if (rxListQuery.data) {
    return (
      <PageContainer background="cream">
        {rxListQuery.data.rxs.length ? <Wallet rxData={rxListQuery.data} /> : <EmptyWallet />}
      </PageContainer>
    );
  }

  return (
    <PageContainer background="cream">
      <div>No data loaded.</div>
    </PageContainer>
  );
}
