import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 1.86rem;
  line-height: 1.14;
  font-weight: 600;
  color: var(--black);
  text-align: center;
  margin: 1.75rem 0;
  margin-top: 2.13rem;

  &:has(+ h3[class*='Subtitle']) {
    // if this Title has a sibling that is a Subtitle
    margin-bottom: 0.26rem;
  }
`;

export const Subtitle = styled.h3`
  font-weight: 400;
  color: var(--black);
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding: 0;
  text-align: center;
`;

export const Heading = styled.h2`
  font-weight: 500;
  font-size: 1rem;
  color: var(--black);
`;
