import warning from '../assets/warning.svg';
import chevronRight from '../assets/chevronRight.svg';
import { FlexRow } from './Layout';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

export const WarningLink: React.FC<{ text: string } & LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = (
  props,
) => {
  const { text, to, ...rest } = props;
  return (
    <WarningLinkAnchor to={to} {...rest}>
      <WarningContainer>
        <WarningIcon src={warning} alt="" />
        {text}
        <WarningChevron src={chevronRight} alt="" />
      </WarningContainer>
    </WarningLinkAnchor>
  );
};

const WarningContainer = styled(FlexRow)`
  justify-content: flex-end;
  align-items: center;
`;

const WarningLinkAnchor = styled(Link)``;

const WarningIcon = styled.img`
  height: 1.33rem;
  margin-right: 0.5rem;
`;

const WarningChevron = styled.img`
  height: 0.66rem;
  margin-left: 0.53rem;
`;
