import styled from 'styled-components';

export const PharmacyAddress = styled.div.attrs({ role: 'group' })`
  font-weight: 400;
  color: var(--purple-dark);

  div:first-child {
    color: var(--black);
    font-weight: 500;
  }
`;
