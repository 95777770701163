import { AxiosError } from 'axios';
import type { PatientPortalErrorResponse, Pharmacy, Rx, RxUICollapsedStatus } from './sharedTypes';
import moment from 'moment';

function getNumberOrExecute(fn: number | (() => number)) {
  if (typeof fn === 'function') {
    return fn();
  }
  return fn;
}

type Position = google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;

/** Given two points, returns the distance between them in miles */
export function haversineDistance(pos1: Position, pos2: Position) {
  if (!pos1 || !pos2) return NaN;
  const R = 3958.8; // Radius of the Earth in miles
  const lat1 = getNumberOrExecute(pos1.lat);
  const lat2 = getNumberOrExecute(pos2.lat);
  const lng1 = getNumberOrExecute(pos1.lng);
  const lng2 = getNumberOrExecute(pos2.lng);

  const rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (lng2 - lng1) * (Math.PI / 180); // Radian difference (longitudes)

  const d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2),
      ),
    );
  // round to 1 decimal place
  return Math.round(d * 10) / 10;
}

export const formatPharmacyAddress = (pharmacy: Pharmacy) => {
  const { address_line_1, address_line_2, city, state, zip_code } = pharmacy;
  const shortAddress = `${address_line_1} ${address_line_2 || ''}`.trim();
  const locale = `${city}, ${state} ${zip_code}`;
  const fullAddress = `${shortAddress}, ${locale}`;

  return {
    shortAddress,
    locale,
    fullAddress,
  };
};

// formats human-readable phone number
export const formatPhoneNumber = (phone_number: string): string => {
  //normalize string and remove all unnecessary characters
  const phone = phone_number.replace(/[^\d]/g, '');

  //check if number length equals to 10
  if (phone.length == 10) {
    //reformat and return phone number
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return phone;
};

// formats tel: link for phone number (also needed for screen readers)
export const phoneNumberHref = (phone_number: string): string => {
  //normalize string and remove all unnecessary characters
  const phone = phone_number.replace(/[^\d]/g, '');

  //check if number length equals to 10
  if (phone.length == 10) {
    //reformat and return phone number
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, 'tel:$1$2$3');
  }

  return phone;
};

export const getGoogleMapsUrl = (address: string): string => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
};

export const getErrorMessage = (error: AxiosError<PatientPortalErrorResponse>): string => {
  return error.response?.data.error || 'An error occurred.';
};

export const statusToUICollapsedStatus = (rx: Rx): RxUICollapsedStatus => {
  if (rx.status === 'Canceled') {
    return 'Canceled';
  } else if (rx.status === 'Completed' || rx.transfer_status === 'Ready') {
    return 'Ready';
  } else {
    return 'In Progress';
  }
};

export const statusToUIExpandedStatus = (rx: Rx): string[] => {
  const statuses = [];
  const uiCollapsedStatus = statusToUICollapsedStatus(rx);

  if (uiCollapsedStatus === 'Canceled') {
    statuses.push('Canceled');
    // if canceled, it should be the only status
    return statuses;
  }

  // pharmacy status
  if (uiCollapsedStatus === 'Ready') {
    switch (rx.fulfillment_type) {
      case 'pickup':
      default:
        statuses.push('Ready for pickup');
        break;
      case 'delivery':
        statuses.push('Ready for delivery');
        break;
    }
  } else if (rx.transfer_status === 'Confirmed') {
    statuses.push('Pharmacy received prescription');
  }

  // insurance status
  if (rx.pa_pending) {
    statuses.push('Waiting on insurance approval');
  }

  // bridge/financial assistance status
  if (rx.financial_status === 'Enrolling') {
    statuses.push('Enrolling in financial assistance');
  } else if (rx.financial_status === 'Enrolled') {
    statuses.push('Enrolled in financial assistance');
  } else if (rx.bridge_status === 'Enrolling') {
    statuses.push('Enrolling in bridge program');
  } else if (rx.bridge_status === 'Enrolled') {
    statuses.push('Enrolled in bridge program');
  }

  if (statuses.length === 0) {
    // none of the above statuses apply
    statuses.push('Prescription written');
  }

  return statuses;
};

export const sortRxComparator = (a: Rx, b: Rx) => {
  const aStatus = statusToUICollapsedStatus(a);
  const bStatus = statusToUICollapsedStatus(b);

  const statusToOrder = (status: RxUICollapsedStatus): number => {
    switch (status) {
      case 'Canceled':
        return 1;
      case 'Ready':
        return 0;
      case 'In Progress':
        return -1;
    }
  };

  if (aStatus !== bStatus) {
    return statusToOrder(aStatus) - statusToOrder(bStatus);
  } else {
    return moment(a.written_date) < moment(b.written_date) ? 1 : -1;
  }
};
