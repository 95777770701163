import styled from 'styled-components';

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const LinkButton = styled(IconButton)`
  color: var(--black);
  line-height: 1.33;
  text-decoration: underline;
  text-underline-offset: 3px;
  white-space: pre-wrap;
  font-family: 'Haffer', system-ui, Helvetica, Arial, sans-serif;
`;

export const BaseButtonMixin = `
  height: 44px;
  padding: 0.8rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  font-weight: 600;
  color: var(--black);
  font-family: 'Haffer', system-ui, Helvetica, Arial, sans-serif;
`;

export const StyledButton = styled.button<{ $fullWidth?: boolean }>`
  ${BaseButtonMixin}
  ${({ $fullWidth }) => ($fullWidth ? 'border-radius: 0.8rem;' : 'border-radius: 0.66rem;')}
  border: none;

  background: none;
  ${({ $fullWidth }) => ($fullWidth ? 'width: 100%;' : 'display: block; margin: 0 auto;')}

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--purple);
  }

  &:disabled {
    background-color: var(--button-background);
    color: var(--purple-dark);
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(StyledButton)`
  background: var(--purple);
  color: var(--white);
`;

export const SecondaryButton = styled(StyledButton)`
  background: var(--button-background);
`;

export const ButtonGroup = styled.div<{ $direction?: 'column' | 'row' }>`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: ${({ $direction = 'column' }) => $direction};
  gap: 0.8rem;

  button {
    flex-grow: 1;
  }
`;
