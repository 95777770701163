import styled from 'styled-components';

export const ProgressBar: React.FC<{ percent: number }> = ({ percent }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFill $percent={percent} />
    </ProgressBarContainer>
  );
};

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.25rem;
  background-color: var(--border-grey);
  border-radius: 0.25rem;
  margin: 0.5rem 0;
`;

const ProgressBarFill = styled.div<{ $percent: number }>`
  height: 100%;
  width: ${({ $percent }) => `${$percent}%`};
  background-color: var(--purple);
  border-radius: 0.25rem;
`;
