import axios from 'axios';

const apiKey = import.meta.env.VITE_GOOGLE_CLIENT_KEY;
const BASE_PLACES_URL = 'https://places.googleapis.com/v1/places';

const fields = [
  'displayName',
  'id',
  'nationalPhoneNumber',
  'location',
  'googleMapsUri',
  'formattedAddress',
  'addressComponents',
];

const prefixedFields = fields.map((field) => `places.${field}`);

type Location = {
  latitude: number;
  longitude: number;
};

export const convertLatLngToLocation = (latLng: google.maps.LatLng): Location => {
  return {
    latitude: latLng.lat(),
    longitude: latLng.lng(),
  };
};

export const convertLocationToLatLng = (location: Location): google.maps.LatLngLiteral => {
  return {
    lat: location.latitude,
    lng: location.longitude,
  };
};

/**
 * Runs a Google Places Nearby Search for pharmacies within 1km of the given location.
 * Returns an array of Place results, or an empty array on error.
 * https://developers.google.com/maps/documentation/places/web-service/nearby-search.
 */
export async function nearbySearch(location: Location) {
  try {
    const response = await axios.post(
      `${BASE_PLACES_URL}:searchNearby`,
      {
        includedTypes: ['pharmacy'],
        maxResultCount: 20,
        locationRestriction: {
          circle: {
            center: location,
            radius: 1000.0,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': apiKey,
          'X-Goog-FieldMask': prefixedFields.join(','),
        },
      },
    );

    return response.data.places as google.maps.places.Place[];
  } catch (error) {
    console.log(error);

    return [];
  }
}

/**
 * Runs a Google Places Text Search for pharmacies near the given text query.
 * Returns an array of Place results, or an empty array on error.
 * https://developers.google.com/maps/documentation/places/web-service/text-search.
 */
export async function textSearch(query: string) {
  try {
    const response = await axios.post(
      `${BASE_PLACES_URL}:searchText`,
      {
        textQuery: query,
        includedType: 'pharmacy',
        maxResultCount: 20,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': apiKey,
          'X-Goog-FieldMask': prefixedFields.join(','),
        },
      },
    );

    return response.data.places as google.maps.places.Place[];
  } catch (error) {
    console.log(error);

    return [];
  }
}

/**
 * Runs a Google Place Detail search for the given place ID.
 * Returns a single Place result if place is a pharmacy, or undefined.
 * https://developers.google.com/maps/documentation/places/web-service/place-details.
 */
export async function getPlaceDetails(placeID: string) {
  try {
    const response = await axios.get(`${BASE_PLACES_URL}/${placeID}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': apiKey,
        'X-Goog-FieldMask': [...fields, 'types'].join(','),
      },
    });
    if (!response.data.types.includes('pharmacy')) {
      return undefined;
    }

    return response.data as google.maps.places.Place;
  } catch (error) {
    console.log(error);

    return undefined;
  }
}
