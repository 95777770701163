import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { client } from '../api';
import { CardExpandedContent, ClickableCard } from '../components/Card';
import { FlexColumn, FlexRow } from '../components/Layout';
import { ButtonGroup, PrimaryButton, SecondaryButton } from '../components/Button';
import { RadioButton } from './Radio';
import { MapStatic } from './MapStatic';
import { PharmacyAddress } from './PharmacyAddress';

type MapsPharmacy = {
  address: string;
  distance: number;
  location: google.maps.LatLngLiteral;
  name: string;
  phone: string;
  placeID: string;
  url: string;
  addressComponents: google.maps.places.AddressComponent[];
};

type SubmitResponse = {
  status: string;
};

const extractAddressComponents = (addressComponents: google.maps.places.AddressComponent[]) => {
  const fmtZipCode = (zip: string | null | undefined) => {
    if (!zip) return '';
    return zip.length === 5 ? zip : zip.slice(0, 5);
  };
  return {
    address_line_1:
      addressComponents.find((obj) => obj.types.includes('street_address'))?.shortText ||
      (addressComponents.find((obj) => obj.types.includes('street_number'))?.shortText || '') +
        ' ' +
        (addressComponents.find((obj) => obj.types.includes('route'))?.shortText || ''),
    city:
      addressComponents.find((obj) => obj.types.includes('locality') || obj.types.includes('sublocality'))?.longText ||
      '',
    state: addressComponents.find((obj) => obj.types.includes('administrative_area_level_1'))?.shortText || '',
    zip_code: fmtZipCode(addressComponents.find((obj) => obj.types.includes('postal_code'))?.shortText) || '',
  };
};

export const ResultCard = (props: { pharmacy: MapsPharmacy; onClick: () => void; isExpanded: boolean }) => {
  const { pharmacy, onClick, isExpanded } = props;
  const { name, address, distance, addressComponents } = pharmacy;

  const { state } = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const submit = useMutation<SubmitResponse, Error, MapsPharmacy>({
    mutationFn: async (data) => {
      const response = await client.post('/update-preferred-pharmacy', {
        name: data.name,
        address: data.address,
        phone_number: data.phone,
        google_place_id: data.placeID,
        lat: data.location.lat,
        lng: data.location.lng,
        ...extractAddressComponents(data.addressComponents),
      });
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['preferredPharmacy'] });
      const { source } = state || {};
      if (source) {
        navigate(source);
      } else {
        navigate('/pharmacy-confirmation');
      }
    },
  });

  const footerActions = (
    <ButtonGroup>
      <PrimaryButton
        $fullWidth
        disabled={submit.isLoading}
        onClick={() => {
          submit.mutate(pharmacy);
        }}
      >
        Select pharmacy
      </PrimaryButton>
      <SecondaryButton
        $fullWidth
        onClick={() => {
          onClick();
        }}
      >
        Cancel
      </SecondaryButton>
    </ButtonGroup>
  );

  const { address_line_1, city, state: address_state, zip_code } = extractAddressComponents(addressComponents);

  return (
    <StyledResultCard onClick={onClick} $expanded={isExpanded} aria-label={name} role="radio">
      <FlexColumn>
        <StyledRadio>
          <RadioButton $checked={isExpanded} />
          <FlexRow>
            <PharmacyAddress>
              <div>{name}</div>
              <div>{address_line_1}</div>
              <div>{`${city}, ${address_state} ${zip_code}`}</div>
            </PharmacyAddress>
            <StyledDistance aria-label={`${distance} miles`}>{distance} mi</StyledDistance>
          </FlexRow>
        </StyledRadio>
        <CardExpandedContent $expanded={isExpanded}>
          <MapStatic center={`${name} ${address}`} zoom={16} width={1000} height={200}></MapStatic>
          {footerActions}
        </CardExpandedContent>
      </FlexColumn>
    </StyledResultCard>
  );
};

const StyledResultCard = styled(ClickableCard)<{ expanded?: boolean }>`
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  gap: ${({ expanded }) => (expanded ? '0.75rem' : '0.25rem')};
`;

const StyledDistance = styled.div`
  font-size: 0.93rem;
  font-weight: 400;
  color: var(--purple-dark);
`;

const StyledRadio = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
`;
