import { Subtitle, Title } from '../components/Typography';
import { PageContainer } from '../components/PageContainer';

export function FourOhFour() {
  return (
    <PageContainer>
      <Title>Page not found</Title>
      <Subtitle>The page you're looking for doesn't exist.</Subtitle>
    </PageContainer>
  );
}
