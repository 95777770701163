import styled from 'styled-components';
import { getGoogleMapsUrl } from '../utils';

interface MapStaticProps {
  center: string; // an address or lat,lng to center the map image on
  zoom?: number;
  width?: number;
  height?: number;
  pinSize?: MapPinSize;
}

type MapPinSize = 'small' | 'tiny' | 'mid';

export const MapStatic = ({ center, zoom = 14, width = 72, height = 72, pinSize = 'mid' }: MapStaticProps) => {
  const mapImageApiParams = {
    center: encodeURIComponent(center),
    markers: `size:${pinSize}|${encodeURIComponent(center)}`,
    zoom: encodeURIComponent(zoom),
    size: encodeURIComponent(`${width}x${height}`),
    mapType: 'roadmap',
    scale: '2',
    key: encodeURIComponent(import.meta.env.VITE_GOOGLE_CLIENT_KEY),
  };

  const mapImageApiParamString = Object.entries(mapImageApiParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?${mapImageApiParamString}`;
  // TODO signed requests? https://developers.google.com/maps/documentation/maps-static/digital-signature

  const mapUrl = getGoogleMapsUrl(center);

  return (
    <MapContainer>
      <ImageLink href={mapUrl} target="_blank">
        <Image src={mapImageUrl} alt={`Map of ${center}`}></Image>
      </ImageLink>
    </MapContainer>
  );
};

const MapContainer = styled.div`
  border-radius: 0.8rem;
  overflow: hidden;
`;

const ImageLink = styled.a`
  display: inline-block;
  vertical-align: top;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
