import heroPharmacy from '../assets/hero-pharmacy.svg';
import heroInsurance from '../assets/hero-insurance.svg';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '../components/Button';
import { Title, Subtitle } from '../components/Typography';
import { PageContainer } from '../components/PageContainer';

export function PharmacyLaunchPoint() {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Hero src={heroPharmacy} alt="" />
      <Title>Add your pharmacy</Title>
      <Subtitle>We'll use this as your default pharmacy to send your prescriptions to.</Subtitle>
      <PrimaryButton onClick={() => navigate('/set-pharmacy')}>Add pharmacy</PrimaryButton>
    </PageContainer>
  );
}

export function InsuranceLaunchPoint() {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Hero src={heroInsurance} alt="" />
      <Title>Add your insurance</Title>
      <Subtitle>We'll use this to see if your insurance will cover your prescriptions.</Subtitle>
      <PrimaryButton onClick={() => navigate('/upload-insurance')}>Add insurance</PrimaryButton>
    </PageContainer>
  );
}

const Hero = styled.img`
  display: block;
  margin: 1rem auto;
  height: 200px;
`;
