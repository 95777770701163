import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { client } from '../api';
import { Navigate, useNavigate } from 'react-router-dom';
import { PageContainer } from '../components/PageContainer';
import { Title, Subtitle } from '../components/Typography';
import { FlexColumn } from '../components/Layout';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../auth';
import { AxiosError } from 'axios';
import { PatientPortalErrorResponse } from '../sharedTypes';
import { getErrorMessage } from '../utils';
import styled from 'styled-components';
import { Input } from '../components/Input';
// import { StyledLink } from "../components/StyledLink";

type PhoneFormData = { phone: string };
type PasswordFormData = { phone: string; password: string };

type LoginFormState = 'enterPhone' | 'enterPassword' | 'tokenSent';

export const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [loginFormState, _setLoginFormState] = useState<LoginFormState>('enterPhone');
  const setLoginFormState = (state: LoginFormState) => {
    // when moving to the next form state, add the current state to history
    window.history.pushState({ loginFormState }, '');
    _setLoginFormState(state);
  };

  const [phoneState, setPhoneState] = useState<string>();

  const onBack = (ev: PopStateEvent) => {
    // listener for back button
    if ('loginFormState' in ev.state) {
      // if there is a login form state in history, set it
      setLoginFormState(ev.state.loginFormState);
      if (ev.state.loginFormState === 'enterPhone') {
        // if we're going back to the phone form, clear the phone state
        setPhoneState(undefined);
      }
    }
    // if there is no login form state in history (we have pressed back all the way to the beginning),
    // default back button behavior happens and we exit the app
  };

  useEffect(() => {
    // initialize history with starting form state and add listener for back button
    history.replaceState({ loginFormState }, '');
    window.addEventListener('popstate', onBack);
    return () => {
      window.removeEventListener('popstate', onBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once to initialize history and listeners
  }, []);

  const submitPhone = useMutation<{ flow: 'token' | 'login' }, AxiosError<PatientPortalErrorResponse>, PhoneFormData>({
    mutationFn: async (data) => {
      return client.post(`login-flow`, data).then((res) => res.data);
    },
    onSuccess: async (res_data, form_data) => {
      if (res_data.flow === 'token') {
        submitSendToken.mutate({ phone: form_data.phone });
      } else {
        setLoginFormState('enterPassword');
      }
    },
  });

  const submitSendToken = useMutation<{ status: string }, AxiosError<PatientPortalErrorResponse>, PhoneFormData>({
    mutationFn: async (data) => {
      return client.post(`send-login-token`, data).then((res) => res.data);
    },
    onSuccess: async () => {
      setLoginFormState('tokenSent');
    },
  });

  const submitLogin = useMutation<{ status: string }, AxiosError<PatientPortalErrorResponse>, PasswordFormData>({
    mutationFn: async (data) => {
      return client.post(`login`, data).then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      navigate('/rx');
    },
  });

  if (authContext.loaded && authContext.auth === 'full') {
    return <Navigate to={'/rx'} />;
  }

  const getError = (): string | undefined => {
    if (loginFormState === 'enterPhone' && submitPhone.error) {
      return getErrorMessage(submitPhone.error);
    }
    if (loginFormState === 'enterPassword' && submitLogin.error) {
      return getErrorMessage(submitLogin.error);
    }
    if (loginFormState !== 'tokenSent' && submitSendToken.error) {
      return getErrorMessage(submitSendToken.error);
    }
  };

  const getSuccess = (): string | undefined => {
    if (loginFormState === 'tokenSent' && submitSendToken.data?.status === 'ok') {
      return 'Sent to your phone';
    }
  };

  const PhoneForm = () => {
    const { handleSubmit, control, formState } = useForm<PhoneFormData>({});

    const onSubmit = (data: PhoneFormData) => {
      setPhoneState(data.phone);
      submitPhone.mutate(data);
    };

    return (
      <form>
        <FlexColumn $spacing="loose">
          <Controller
            control={control}
            defaultValue=""
            name="phone"
            rules={{ required: true }}
            render={({ field }) => <Input label={'Phone number'} {...field} />}
          />
          <PrimaryButton $fullWidth disabled={!formState.isValid} onClick={handleSubmit(onSubmit)}>
            Continue
          </PrimaryButton>
        </FlexColumn>
      </form>
    );
  };

  const PasswordForm = () => {
    const { handleSubmit, control, formState } = useForm<PasswordFormData>({});

    const onSubmitLogin = (data: PasswordFormData) => {
      submitLogin.mutate(data);
    };

    const onSubmitToken = (data: PasswordFormData) => {
      submitSendToken.mutate({ phone: data.phone });
    };

    return (
      <form>
        <FlexColumn $spacing="loose">
          <Controller
            control={control}
            defaultValue={phoneState}
            name="phone"
            render={({ field }) => <input type="hidden" {...field} />}
          />
          <Controller
            control={control}
            defaultValue=""
            name="password"
            render={({ field }) => <Input label="Password" type="password" {...field} />}
          />
          <PrimaryButton $fullWidth disabled={!formState.isValid} onClick={handleSubmit(onSubmitLogin)}>
            Continue
          </PrimaryButton>
          {/* TODO uncomment when forgot password flow is working */}
          {/* <StyledLinkBlock to="/forgot-password">
            Forgot password?
          </StyledLinkBlock> */}
        </FlexColumn>
        <OrDivider>or</OrDivider>
        <SecondaryButton $fullWidth onClick={handleSubmit(onSubmitToken)}>
          Send one-time link
        </SecondaryButton>
      </form>
    );
  };

  const TokenSentForm = () => {
    const { handleSubmit, control, formState } = useForm<PhoneFormData>({});

    return (
      <>
        <Subtitle>Link sent to the phone number provided</Subtitle>
        <form>
          <FlexColumn $spacing="loose">
            <Controller
              control={control}
              defaultValue={phoneState}
              name="phone"
              render={({ field }) => <input type="hidden" {...field} />}
            />
            <PrimaryButton
              disabled={submitSendToken.isLoading || formState.isSubmitted}
              onClick={handleSubmit((data) => {
                submitSendToken.mutate({ phone: data.phone });
              })}
            >
              Resend link
            </PrimaryButton>
          </FlexColumn>
        </form>
      </>
    );
  };

  return (
    <PageContainer
      banner={
        getError()
          ? { variant: 'error', title: getError() }
          : getSuccess()
            ? { variant: 'success', title: getSuccess() }
            : undefined
      }
    >
      <StyledTitle>{loginFormState === 'tokenSent' ? 'Check your texts' : 'Log in to Tandem'}</StyledTitle>
      {loginFormState === 'enterPhone' ? (
        <PhoneForm />
      ) : loginFormState === 'enterPassword' ? (
        <PasswordForm />
      ) : loginFormState === 'tokenSent' ? (
        <TokenSentForm />
      ) : null}
    </PageContainer>
  );
};

const StyledTitle = styled(Title)`
  margin-bottom: 1.75rem;
`;

const OrDivider = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  margin: 2.13rem 0;

  &:before {
    margin-left: -52%;
    text-align: right;
  }

  &:after {
    margin-left: 2%;
  }

  &:before, &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: var(--border-grey);

`;
