import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';

import { client } from '../api';
import { TANDEM_PHONE_NUMBER } from '../constants';
import { statusToUICollapsedStatus, statusToUIExpandedStatus } from '../utils';
import { CardExpandedContent, CardSection, ClickableCard } from './Card';
import { FlexColumn, FlexRow } from './Layout';
import { StyledLinkPrimary } from './StyledLink';

import type { Pharmacy, Rx } from '../sharedTypes';
import { LabelValue } from './LabelValue';
import { WarningLink } from './WarningLink';
import { ProgressBar } from './ProgressBar';
import { PharmacyInfo } from './PharmacyInfo';

type PharmacyResponse = {
  pharmacy: Pharmacy;
  status: 'pending' | 'transferred';
};

export function RxCard(props: {
  rx: Rx;
  expanded: boolean;
  toggleExpand: () => void;
  setModalPharmacy: (pharmacy: Pharmacy) => void;
}) {
  const { rx, expanded, toggleExpand, setModalPharmacy } = props;

  const renderRxInfo = (rx: Rx) => {
    return (
      <>
        <LabelValue label="Refills" value={rx.drug_refills ? `${rx.drug_refills} left` : '-'} />
        <LabelValue label="Prescribed on" value={moment(rx.written_date).format('MMM D, YYYY')} />
        <LabelValue label="Provider" value={`${rx.prescriber_first_name} ${rx.prescriber_last_name}`} />
      </>
    );
  };

  const renderButtons = (rx: Rx) => {
    return (
      <RxCardButtons>
        <StyledLinkPrimary
          to={`sms:${TANDEM_PHONE_NUMBER}&body=${encodeURIComponent(
            `Hi. I need help with my prescription for ${rx.drug_name}.`,
          )}`}
        >
          Get help
        </StyledLinkPrimary>
      </RxCardButtons>
    );
  };

  const { data, isLoading, isError } = useQuery(['rxPharmacy', rx.id], async () => {
    return client.get(`rx/${rx.id}/pharmacy`).then((res) => res.data as PharmacyResponse);
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  const showAddPharmacy = rx.status !== 'Canceled' && !data.pharmacy && rx.transfer_status === 'Pending';
  const collapsedStatus = statusToUICollapsedStatus(rx);
  const expandedStatuses = statusToUIExpandedStatus(rx);

  const getProgressPercent = () => {
    switch (collapsedStatus) {
      case 'In Progress':
        if (expandedStatuses.length === 1 && expandedStatuses.includes('Prescription written')) {
          return 33;
        }
        return 66;
      case 'Ready':
        return 100;
      case 'Canceled':
        return 0;
      default:
        return 33;
    }
  };

  return (
    <ClickableCard $expanded={expanded} onClick={toggleExpand} data-testid="rx-card" aria-label={rx.drug_name}>
      <CardSection $noBorder>
        <FlexRow>
          <FlexColumn $fullWidth $spacing="none">
            <div role="group">
              <RxCardTitle $expanded={expanded}>{rx.drug_name}</RxCardTitle>
              <RxCardDrugInfo $expanded={expanded}>
                {rx.drug}
                <RxStatusInfo $expanded={expanded}>
                  <MiddotSpacer>&middot;</MiddotSpacer>
                  {collapsedStatus}
                </RxStatusInfo>
                <RxExpandedStatusInfo $expanded={expanded}>
                  <RxStatusDisclosure>
                    <summary>
                      {expandedStatuses[0]}{' '}
                      {expandedStatuses.length > 1 ? <span>{`+${expandedStatuses.length - 1} more`}</span> : null}
                    </summary>
                    {expandedStatuses.slice(1).map((status, idx) => (
                      <div key={idx}>{status}</div>
                    ))}
                  </RxStatusDisclosure>
                  <ProgressBar percent={getProgressPercent()} />
                </RxExpandedStatusInfo>
              </RxCardDrugInfo>
            </div>
          </FlexColumn>
        </FlexRow>
      </CardSection>
      <CardExpandedContent $expanded={expanded}>
        <CardSection>
          <RxCardSubtitle>Details</RxCardSubtitle>
          {renderRxInfo(rx)}
        </CardSection>
        <CardSection>
          {data.pharmacy || showAddPharmacy ? (
            <RxPharmacySubtitleRow $centerAlign>
              <RxCardSubtitle>Pharmacy</RxCardSubtitle>
              {showAddPharmacy ? (
                <WarningLink text="Add" to={`/rx/${rx.id}/set-pharmacy`} state={{ source: '/rx' }} />
              ) : null}
            </RxPharmacySubtitleRow>
          ) : null}
          {data.pharmacy ? (
            <PharmacyInfo pharmacy={data.pharmacy} onClick={() => setModalPharmacy(data.pharmacy)}></PharmacyInfo>
          ) : undefined}
          {renderButtons(rx)}
        </CardSection>
      </CardExpandedContent>
    </ClickableCard>
  );
}

const RxCardTitle = styled.h2<{ $expanded: boolean }>`
  font-size: ${({ $expanded }) => ($expanded ? '1.4rem' : '1.13rem')};
  line-height: 1.23;
  font-weight: 600;
`;

const RxCardSubtitle = styled.h3`
  font-size: 1.13rem;
  font-weight: 500;
  margin-bottom: 0.53rem;
`;

const RxCardDrugInfo = styled.div<{ $expanded: boolean }>`
  font-weight: 400;
`;

const RxPharmacySubtitleRow = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: 1rem;

  & [class*='RxCardSubtitle'] {
    margin-bottom: 0;
  }
`;

const RxStatusInfo = styled.div<{ $expanded: boolean }>`
  display: ${({ $expanded }) => ($expanded ? 'none' : 'inline')};
`;

const RxExpandedStatusInfo = styled.div<{ $expanded: boolean }>`
  display: ${({ $expanded }) => ($expanded ? 'block' : 'none')};
  margin-top: 0.53rem;
  font-weight: 500;

  cursor: pointer;

  summary {
    list-style: none;
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary span {
    text-decoration: underline;
  }

  details[open] summary span {
    display: none;
  }
`;

const RxStatusDisclosure = styled.details``;

const MiddotSpacer = styled.span`
  margin: 0 0.5rem;
`;

const RxCardButtons = styled(FlexColumn)`
  margin-top: 1.33rem;
`;
