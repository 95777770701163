import React from 'react';
import styled from 'styled-components';

import checkCircle from '../assets/checkCircle.svg';
import warning from '../assets/warning.svg';
import { Heading } from './Typography';
import { BODY_MAX_WIDTH } from '../constants';

type Variant = 'success' | 'error';
export type BannerProps = {
  variant: Variant;
  title: string | React.ReactNode;
};

const variantToIcon = (variant: Variant) => {
  switch (variant) {
    case 'success':
      return <img src={checkCircle} alt="success" />;
    case 'error':
      return <img src={warning} alt="error" />;
    default:
      return undefined;
  }
};

const variantToBgColor = (variant: Variant) => {
  switch (variant) {
    case 'success':
      return 'var(--green-light)';
    case 'error':
      return 'var(--red-light)';
    default:
      return 'var(--border-grey)';
  }
};

const variantToColor = (variant: Variant) => {
  switch (variant) {
    case 'success':
      return 'var(--green-text)';
    case 'error':
      return 'var(--red-text)';
    default:
      return 'var(--black)';
  }
};

export const Banner: React.FC<BannerProps> = (props: BannerProps) => {
  const { variant, title, ...rest } = props;
  return (
    <StyledBanner $variant={variant} {...rest}>
      <BannerContainer>
        {variantToIcon(variant)}
        <Heading>{title}</Heading>
      </BannerContainer>
    </StyledBanner>
  );
};

const BannerContainer = styled.div`
  max-width: ${BODY_MAX_WIDTH};
  margin: 0 auto;
  display: flex;
  flex: auto;
  justify-content: center;
`;

const StyledBanner = styled.div<{ $variant: Variant }>`
  position: sticky;
  z-index: 1;
  top: 52px; // height of StyledHeader in PageContainer.tsx
  height: 52px;
  background-color: ${({ $variant }) => variantToBgColor($variant)};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ $variant }) => variantToColor($variant)};
  padding: 0.75rem;
  margin-bottom: 1rem;

  img {
    height: 20px;
    margin-right: 8px;
  }

  a {
    color: ${({ $variant }) => variantToColor($variant)};};
    text-underline-offset: 3px;
  }
`;
