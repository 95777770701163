import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { client } from '../api';
import { PageContainer } from '../components/PageContainer';
import { Subtitle, Title } from '../components/Typography';
import { VerifyIdentity } from '../components/VerifyIdentity';
import { AxiosError } from 'axios';
import { PatientPortalErrorResponse } from '../sharedTypes';
import { getErrorMessage } from '../utils';

type FormData = { dob: moment.Moment; last_name: string };
export type RegisterTokenResponse = { status: string; add_password?: boolean };

export const ClaimProfile = () => {
  const { handleSubmit, control, formState } = useForm<FormData>({});

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  const submit = useMutation<RegisterTokenResponse, AxiosError<PatientPortalErrorResponse>, FormData>({
    mutationFn: (data) => {
      const dob = data.dob.format('YYYY-MM-DD');
      return client
        .post(`register/${token}`, {
          ...data,
          dob,
        })
        .then((res) => res.data);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      if (data.add_password) {
        navigate(`/add-password`);
      } else {
        navigate('/rx');
      }
    },
  });

  return (
    <PageContainer banner={submit.error ? { variant: 'error', title: getErrorMessage(submit.error) } : undefined}>
      <Title>Verify your identity</Title>
      <Subtitle>We need to confirm your identify before showing protected health information.</Subtitle>
      <VerifyIdentity
        handleSubmit={handleSubmit}
        control={control}
        formState={formState}
        submit={submit}
      ></VerifyIdentity>
    </PageContainer>
  );
};
