import styled from 'styled-components';

export const RadioButton = styled.div<{ $checked: boolean }>`
  appearance: none;
  margin: 0;
  min-width: 1.25rem;
  height: 1.25rem;
  background-color: ${({ $checked }) => ($checked ? 'var(--purple)' : 'var(--white)')};
  border: 2px solid ${({ $checked }) => ($checked ? 'var(--purple)' : 'var(--radio-unchecked)')};
  border-radius: 50%;

  display: grid;
  place-content: center;

  &:before {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    transform: ${({ $checked }) => ($checked ? 'scale(1)' : 'scale(0)')};
    box-shadow: inset 1em 1em var(--white);
  }
`;
