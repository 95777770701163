import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { IconButton } from './Button';
import { Heading } from './Typography';

import close from '../assets/close.svg';
import chevronLeft from '../assets/chevronLeft.svg';
import logo from '../assets/logo.svg';
import hamburger from '../assets/hamburger.svg';
import { OutboundLink } from './StyledLink';
import { Banner, BannerProps } from './Banner';
import { AuthContext } from '../auth';
import { BODY_MAX_WIDTH } from '../constants';

type PageType = 'loggedOut' | 'loggedIn' | 'modal' | 'menu';

type Props = {
  children: React.ReactNode;
  pageType?: PageType;
  title?: string;
  banner?: BannerProps;
  footer?: React.ReactNode;
  background?: 'white' | 'cream';
};

export function PageContainer(props: Props) {
  const { children, pageType, banner, title, footer, background } = props;
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const getLoggedOutHeader = () => (
    <StyledHeader>
      <LogoImg src={logo} alt="Tandem" />
      <OutboundLink href="https://tndmrx.com/m">How it works</OutboundLink>
    </StyledHeader>
  );

  const getLoggedInHeader = () => (
    <StyledHeader>
      <LogoImg src={logo} alt="Tandem" />
      <RightHeaderIcon onClick={() => navigate('/settings')}>
        <img src={hamburger} alt="Menu" />
      </RightHeaderIcon>
    </StyledHeader>
  );

  const getModalHeader = (title?: string) => {
    return (
      <StyledHeader>
        <LeftHeaderIcon onClick={() => navigate(-1)}>
          <img src={close} alt="Close" />
        </LeftHeaderIcon>
        {title ? <Heading>{title}</Heading> : null}
        <EmptyHeaderIcon />
      </StyledHeader>
    );
  };

  const getMenuHeader = (title?: string) => {
    return (
      <StyledHeader>
        <LeftHeaderIcon onClick={() => navigate(-1)}>
          <img height="16" src={chevronLeft} alt="Back" />
        </LeftHeaderIcon>
        {title ? <Heading>{title}</Heading> : null}
        <EmptyHeaderIcon />
      </StyledHeader>
    );
  };

  const getHeader = (type: PageType, title?: string) => {
    switch (type) {
      case 'loggedOut':
        return getLoggedOutHeader();
      case 'loggedIn':
        return getLoggedInHeader();
      case 'modal':
        return getModalHeader(title);
      case 'menu':
        return getMenuHeader(title);
    }
  };

  function getPageType(pageType?: PageType) {
    if (pageType) {
      return pageType;
    }

    if (authContext.loaded) {
      if (authContext.auth === 'full') {
        return 'loggedIn';
      }
    }

    return 'loggedOut';
  }

  return (
    <>
      <StyledPage $background={background || 'white'}>
        {getHeader(getPageType(pageType), title)}
        {banner ? <Banner {...banner} /> : null}
        <StyledBody>
          <StyledBodyContainer>{children}</StyledBodyContainer>
        </StyledBody>
        {footer ? (
          <StyledFooter>
            <StyledBodyContainer>{footer}</StyledBodyContainer>
          </StyledFooter>
        ) : null}
      </StyledPage>
    </>
  );
}

const StyledPage = styled.div<{ $background: 'white' | 'cream' }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh; // needed for iOS to handle address bar showing/hiding
  width: 100vw;
  width: 100dvw; // needed for iOS
  background-color: var(--${({ $background }) => $background});

  hr {
    border: none;
    border-top: 1px solid var(--border-grey);
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    width: 100%;
  }
`;
const StyledHeader = styled.header<{ $border?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.33rem;
  height: 52px;
  min-height: 52px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: var(--white);

  @supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    background: none;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  border-bottom: ${({ $border }) => ($border ? '1px solid var(--grey-medium)' : 'none')};
`;

const HeaderIcon = styled(IconButton)`
  width: 52px;
  height: 52px;
  padding: 0.5em;

  img {
    vertical-align: middle;
  }
`;

const LeftHeaderIcon = styled(HeaderIcon)`
  margin-left: -1rem;
  margin-right: 1rem;
`;

const RightHeaderIcon = styled(HeaderIcon)`
  margin-left: 1rem;
  margin-right: -1rem;
`;

const EmptyHeaderIcon = styled.div`
  width: 52px;
  height: 52px;
  padding: 0.5em;
`;

const LogoImg = styled.img`
  margin: 0;
  margin-top: -6px;
  height: 20px;
`;
const StyledBody = styled.div`
  padding: 1.33rem;
  padding-top: 52px; // height of StyledHeader
  width: 100%;
  overflow-y: auto;

  flex: auto;
`;

// this container is for giving a max width on desktop and centering the content
const StyledBodyContainer = styled.div`
  max-width: ${BODY_MAX_WIDTH};
  margin: 0 auto;
`;

const StyledFooter = styled.div`
  padding: 1rem;
  background: var(--white);
  border-top: 1px solid var(--grey-medium);
`;
