import contact from '../assets/contact.svg';
import faqs from '../assets/faqs.svg';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { client } from '../api';
import { TANDEM_PHONE_NUMBER } from '../constants';
import { FlexColumn } from '../components/Layout';
import type { Pharmacy } from '../sharedTypes';
import { PageContainer } from '../components/PageContainer';
import { PharmacyInfo } from '../components/PharmacyInfo';
import { IconListItemLink } from '../components/IconListItemLink';

export function PharmacyConfirmScreen() {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery<{ pharmacy: Pharmacy }>(['preferredPharmacy'], async () => {
    return client.get('preferred-pharmacy').then((res) => res.data);
  });

  if (isLoading) {
    return (
      <PageContainer>
        <div>Loading...</div>
      </PageContainer>
    );
  }
  if (isError) {
    return (
      <PageContainer>
        <div>Sorry, something went wrong.</div>
      </PageContainer>
    );
  }

  return (
    <PageContainer banner={{ variant: 'success', title: 'Pharmacy saved' }}>
      <ConfirmScreenColumn $spacing="loose">
        <PharmacyInfo
          pharmacy={data.pharmacy}
          onClick={() => {
            navigate('/set-pharmacy');
          }}
        ></PharmacyInfo>

        <ConfirmScreenLinkContainer>
          <IconListItemLink iconSrc={contact} text="Contact us" to={`sms:${TANDEM_PHONE_NUMBER}`} />

          <IconListItemLink iconSrc={faqs} text="FAQs" to="https://withtandem.com/m" target="_blank" />
        </ConfirmScreenLinkContainer>
      </ConfirmScreenColumn>
    </PageContainer>
  );
}

export function InsuranceConfirmScreen() {
  return (
    <PageContainer banner={{ variant: 'success', title: 'Insurance saved' }}>
      <ConfirmScreenColumn $spacing="loose">
        {/* TODO: add ability to edit / preview from this page? */}
        <ConfirmScreenLinkContainer>
          <IconListItemLink iconSrc={contact} text="Contact us" to={`sms:${TANDEM_PHONE_NUMBER}`} />

          <IconListItemLink iconSrc={faqs} text="FAQs" to="https://withtandem.com/m" target="_blank" />
        </ConfirmScreenLinkContainer>
      </ConfirmScreenColumn>
    </PageContainer>
  );
}

const ConfirmScreenLinkContainer = styled.div`
  border-top: 1px solid var(--border-grey);
  padding-top: 1rem;
`;

const ConfirmScreenColumn = styled(FlexColumn)`
  margin-top: 1rem;
`;
