import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Loader } from '@googlemaps/js-api-loader';

import { haversineDistance } from '../utils';
import { TANDEM_PHONE_NUMBER } from '../constants';
import {
  nearbySearch,
  textSearch,
  getPlaceDetails,
  convertLatLngToLocation,
  convertLocationToLatLng,
} from '../googleApi';

import { SearchField } from '../components/Input';
import { StyledLink, StyledLinkPrimary } from '../components/StyledLink';
import { PageContainer } from '../components/PageContainer';
import { ResultCard } from '../components/PharmacyResultCard';
import { ModalSheet, ModalSheetSubtitle, ModalSheetTitle } from '../components/ModalSheet';
import { PrimaryButton, StyledButton } from '../components/Button';
import { FlexColumn } from '../components/Layout';

/* Return a 10km bounding box around center */
const getDefaultBounds = (center: google.maps.LatLngLiteral) => {
  return {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };
};

function requestUserLocation(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

function sortByDistance(
  results: google.maps.places.Place[],
  center?: google.maps.LatLngLiteral | google.maps.LatLng | null,
) {
  return results
    .map((result) => {
      // @ts-expect-error google library types are not updated to new API
      const location = convertLocationToLatLng(result.location);
      return { ...result, location: location, distance: haversineDistance(location, center) };
    })
    .sort((a, b) => {
      return a.distance - b.distance;
    });
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_CLIENT_KEY,
  version: 'weekly',
});

export function PharmacySelection() {
  const autocompleteHtmlRef = useRef<HTMLInputElement>(null);
  const autocompleteApiRef = useRef<google.maps.places.Autocomplete | null>(null);

  const [center, setCenter] = useState<google.maps.LatLngLiteral | google.maps.LatLng | null>();
  const [results, setResults] = useState<google.maps.places.Place[] | null>(null);
  const [openResult, setOpenResult] = useState<number | null>();
  const [isLocationPromptModalOpen, setIsLocationPromptModalOpen] = useState<boolean>(false);

  async function setUserLocation() {
    try {
      // triggers the browser's location permission request if not already granted
      const position = await requestUserLocation();
      const { latitude, longitude } = position.coords;
      const location = { lat: latitude, lng: longitude };
      // set autocomplete bounds to user location
      autocompleteApiRef.current?.setBounds(getDefaultBounds(location));
      // populate initial results based on user location
      const initResults = await nearbySearch({ latitude: location.lat, longitude: location.lng });
      setCenter(location);
      setResults(initResults);
      setIsLocationPromptModalOpen(false);
    } catch (error) {
      // user declined location permission or it was disallowed by mobile OS settings
      // TODO show an error message to user
      autocompleteApiRef.current?.setBounds(undefined);
      setIsLocationPromptModalOpen(false);
    }
  }

  /** Set up autocomplete search box */
  /** This should only be called once on load */
  const setupPlaceSearch = useCallback(async () => {
    if (!autocompleteHtmlRef.current) return;

    const { Autocomplete } = await loader.importLibrary('places');

    const options: google.maps.places.AutocompleteOptions = {
      componentRestrictions: { country: 'us' },
      fields: ['geometry', 'place_id'],
    };

    autocompleteApiRef.current = new Autocomplete(autocompleteHtmlRef.current, options);

    autocompleteApiRef.current.addListener('place_changed', async () => {
      const address = autocompleteApiRef.current!.getPlace();
      if (import.meta.env.DEV) {
        console.log('selection:', address);
      }
      let center;
      let results;
      // if user selected an exact pharmacy from autocomplete, only return that location
      if (address.place_id && address.types?.includes('pharmacy')) {
        const result = await getPlaceDetails(address.place_id);
        if (result) {
          // @ts-expect-error google library types are not updated to new API
          center = convertLocationToLatLng(result.location);
          results = [result];
        }
      }
      // if user selected an exact address that isn't a pharmacy, run nearby search
      if (!results && address.geometry?.location) {
        center = address.geometry.location;
        results = await nearbySearch(convertLatLngToLocation(center));
      } else if (address.name) {
        // fallback to text search
        const searchQuery = `pharmacy near ${address.name}`;
        results = await textSearch(searchQuery);
        if (results) {
          // @ts-expect-error google library types are not updated to new API
          center = convertLocationToLatLng(results[0].location);
        }
      }
      center && setCenter(center);
      results && setResults(results);
    });
  }, [autocompleteHtmlRef]);

  /** Initialize Google Places API */
  useEffect(() => {
    // ensure search always initialized even if user closes out of prompt
    setupPlaceSearch();

    // check if we have location permission
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      switch (permissionStatus.state) {
        case 'denied': // denied means can't prompt again without user action
        case 'granted':
        default:
          setUserLocation();
          break;
        case 'prompt':
          // show location prompt
          setIsLocationPromptModalOpen(true);
          // if user clicks "Enable location" on our prompt, listen
          // for user response to the browser's permission request
          permissionStatus.onchange = () => {
            if (permissionStatus.state !== 'prompt') {
              // whether they accept or deny, close our prompt
              setIsLocationPromptModalOpen(false);
            }
          };
          break;
      }
    });
  }, [setupPlaceSearch]);

  useEffect(() => {
    // Autofocus the search field on mount
    autocompleteHtmlRef.current?.focus();
  }, []);

  return (
    <PageContainer pageType="modal" title="Choose pharmacy">
      <SearchField id="autocomplete-field" label="Enter address" hideLabel ref={autocompleteHtmlRef} />
      {results &&
        (results.length ? (
          <>
            <Stack role="radiogroup">
              {sortByDistance(results, center).map((place, index) => {
                const address = place.formattedAddress || '';
                const pharmacyInfo = {
                  // @ts-expect-error google library types are not updated to new API
                  name: place.displayName?.text,
                  address: address.replace(', USA', ''),
                  location: place.location,
                  phone: place.nationalPhoneNumber || '',
                  distance: place.distance,
                  // @ts-expect-error google library types are not updated to new API
                  url: place.googleMapsUri,
                  placeID: place.id,
                  addressComponents: place.addressComponents!,
                };

                return (
                  <ResultCard
                    key={place.id}
                    pharmacy={pharmacyInfo}
                    onClick={() => {
                      if (openResult === index) {
                        setOpenResult(null);
                      } else {
                        setOpenResult(index);
                      }
                    }}
                    isExpanded={openResult === index}
                  />
                );
              })}
            </Stack>
            <HelpText>
              <div>Don't see your pharmacy?</div>
              <PharmacySelectionContactButton to={`sms:${TANDEM_PHONE_NUMBER}`}>
                Contact us
              </PharmacySelectionContactButton>
            </HelpText>
          </>
        ) : (
          <EmptyContainer>
            No results. <StyledLink onClick={() => autocompleteHtmlRef.current?.focus()}>Update address</StyledLink>
          </EmptyContainer>
        ))}
      <ModalSheet
        $open={isLocationPromptModalOpen}
        onClose={() => {
          setIsLocationPromptModalOpen(false);
        }}
      >
        <ModalSheetInner>
          <ModalSheetTitle>Enable location?</ModalSheetTitle>
          <ModalSheetSubtitle>Enable location to find pharmacies near you.</ModalSheetSubtitle>
          <FlexColumn>
            <PrimaryButton
              $fullWidth
              onClick={() => {
                setUserLocation();
              }}
            >
              Enable location
            </PrimaryButton>
            <StyledButton
              $fullWidth
              onClick={() => {
                setIsLocationPromptModalOpen(false);
              }}
            >
              Not now
            </StyledButton>
          </FlexColumn>
        </ModalSheetInner>
      </ModalSheet>
    </PageContainer>
  );
}

/** Renders stacked list of items */
const Stack = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  gap: 1.06rem;

  > :first-child {
    margin-top: 1.25rem;
  }
`;

const HelpText = styled.div`
  text-align: center;
  color: var(--black);
  margin: 2.13rem 0;

  a {
    margin-top: 1.06rem;
  }
`;

const EmptyContainer = styled.div`
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  color: var(--black);
`;

const ModalSheetInner = styled.div`
  padding: 1.33rem;
`;

const PharmacySelectionContactButton = styled(StyledLinkPrimary)`
  display: inline-block;
`;
