import { useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { client } from '../api';
import { PageContainer } from '../components/PageContainer';
import { Subtitle, Title } from '../components/Typography';
import { FlexColumn } from '../components/Layout';
import { PrimaryButton, SecondaryButton } from '../components/Button';
import { AxiosError } from 'axios';
import { PatientPortalErrorResponse } from '../sharedTypes';
import { getErrorMessage } from '../utils';
import { Input } from '../components/Input';

type FormData = { password: string; confirm_password: string };

export const AddPassword = () => {
  const { handleSubmit, control, formState } = useForm<FormData>({});

  const navigate = useNavigate();

  const submit = useMutation<{ status: string }, AxiosError<PatientPortalErrorResponse>, FormData>({
    mutationFn: (data) => {
      return client.post(`update-password`, data).then((res) => res.data);
    },
    onSuccess: async () => {
      navigate('/rx');
    },
  });

  const atLeastMinimumLength = (password: string) => new RegExp(/(?=.{8,})/).test(password);
  const atLeastOneUppercaseLetter = (password: string) => new RegExp(/(?=.*?[A-Z])/).test(password);
  const atLeastOneLowercaseLetter = (password: string) => new RegExp(/(?=.*?[a-z])/).test(password);
  const atLeastOneNumber = (password: string) => new RegExp(/(?=.*?[0-9])/).test(password);
  const atLeastOneSpecialChar = (password: string) => new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(password);

  const validationRules = {
    atLeastMinimumLength,
    atLeastOneUppercaseLetter,
    atLeastOneLowercaseLetter,
    atLeastOneNumber,
    atLeastOneSpecialChar,
  };

  return (
    <PageContainer banner={submit.error ? { variant: 'error', title: getErrorMessage(submit.error) } : undefined}>
      <Title>Set a password</Title>
      <Subtitle>
        Password must be 8+ characters, with upper and lowercase letters, number, and special character.
      </Subtitle>
      <form
        onSubmit={handleSubmit((data) => {
          submit.mutate(data);
        })}
      >
        <FlexColumn $spacing="loose">
          <div>
            <Controller
              control={control}
              defaultValue=""
              name="password"
              rules={{ required: true, validate: validationRules }}
              render={({ field }) => <Input label="Password" type="password" {...field} />}
            />
          </div>
          <div>
            <Controller
              control={control}
              defaultValue=""
              name="confirm_password"
              rules={{ required: true, validate: validationRules }}
              render={({ field }) => <Input label="Confirm Password" type="password" {...field} />}
            />
          </div>
          <FlexColumn>
            <PrimaryButton type="submit" $fullWidth disabled={!formState.isValid}>
              Continue
            </PrimaryButton>
            <SecondaryButton $fullWidth onClick={() => navigate('/rx')}>
              Not now
            </SecondaryButton>
          </FlexColumn>
        </FlexColumn>
      </form>
    </PageContainer>
  );
};
