import styled from 'styled-components';

import { TANDEM_PHONE_NUMBER } from '../constants';
import { FlexColumn, FlexRow } from '../components/Layout';
import { StyledLink } from '../components/StyledLink';
import { PageContainer } from '../components/PageContainer';
import { Link, useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../components/Button';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { client } from '../api';
import { LabelValue } from '../components/LabelValue';

import contact from '../assets/contact.svg';
import faqs from '../assets/faqs.svg';
import chevronRight from '../assets/chevronRight.svg';
import { IconListItemLink } from '../components/IconListItemLink';
import { WarningLink } from '../components/WarningLink';
import { PharmacyInfo } from '../components/PharmacyInfo';

interface Insurance {
  plan: string;
  member_id: string;
  rx_group: string;
  rx_bin: string;
  pcn: string;
}

const SettingsInsurance = () => {
  const { data, isLoading, isError } = useQuery(['insuranceList'], async () => {
    return client.get('insurance').then((res) => res.data);
  });

  if (isLoading) {
    return null;
  } else if (isError || !data.insurances) {
    return null;
  } else {
    const { insurances } = data;
    const insuranceItems = insurances.map((insurance: Insurance, idx: number) => {
      return (
        <div key={idx}>
          <LabelValue label="Plan" value={insurance.plan || '-'} />
          <LabelValue label="Member ID" value={insurance.member_id || '-'} />
          <LabelValue label="Group ID" value={insurance.rx_group || '-'} />
          <LabelValue label="Rx BIN" value={insurance.rx_bin || '-'} />
          <LabelValue label="PCN" value={insurance.pcn || '-'} />
        </div>
      );
    });
    return (
      <>
        <FlexRow>
          <SettingsLabel>Insurance</SettingsLabel>
          {insurances.length > 0 ? (
            <SettingsActionLink as={Link} to="/upload-insurance" state={{ source: '/settings' }}>
              Edit <img src={chevronRight} alt="" />
            </SettingsActionLink>
          ) : (
            <SettingsWarningLink text="Add" to={'/upload-insurance'} state={{ source: '/settings' }} />
          )}
        </FlexRow>
        {insuranceItems}
      </>
    );
  }
};

const SettingsPharmacy = () => {
  const { data, isLoading, isError } = useQuery(['preferredPharmacy'], async () => {
    return client.get('preferred-pharmacy').then((res) => res.data);
  });

  if (isLoading) {
    return null;
  } else if (isError || !data.pharmacy) {
    return null;
  } else {
    const { pharmacy } = data;
    return (
      <>
        <FlexRow>
          <SettingsLabel>Preferred pharmacy</SettingsLabel>
          {pharmacy ? (
            <SettingsActionLink as={Link} to="/set-pharmacy" state={{ source: '/settings' }}>
              Edit <img src={chevronRight} alt="" />
            </SettingsActionLink>
          ) : (
            <SettingsWarningLink text="Add" to={'/set-pharmacy'} state={{ source: '/settings' }} />
          )}
        </FlexRow>
        <PharmacyInfo pharmacy={pharmacy} onClick={() => {}}></PharmacyInfo>
      </>
    );
  }
};

export function Settings() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const submitLogout = useMutation<{ status: string }>({
    mutationFn: async () => {
      return client.post(`logout`).then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      navigate('/login');
    },
  });

  const signOutButton = (
    <SignOutButton
      $fullWidth
      onClick={() => {
        submitLogout.mutate();
      }}
    >
      Sign out
    </SignOutButton>
  );

  return (
    <PageContainer pageType="menu" title="Menu" footer={signOutButton}>
      <FlexColumn $spacing="none">
        <SettingsItem>
          <FlexColumn>
            <SettingsInsurance />
          </FlexColumn>
        </SettingsItem>

        <SettingsItem>
          <FlexColumn>
            <SettingsPharmacy />
          </FlexColumn>
        </SettingsItem>

        <SettingsLinkContainer>
          <IconListItemLink iconSrc={contact} text="Contact us" to={`sms:${TANDEM_PHONE_NUMBER}`} />

          <IconListItemLink iconSrc={faqs} text="FAQs" to="https://withtandem.com/m" target="_blank" />
        </SettingsLinkContainer>
      </FlexColumn>
    </PageContainer>
  );
}

const SettingsLinkContainer = styled.div`
  margin-top: 1.33rem;
`;

const SettingsLabel = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

const SettingsActionLink = styled(StyledLink)`
  font-size: 0.93rem;
`;

const SettingsWarningLink = styled(WarningLink)`
  font-size: 0.93rem;
`;

const SettingsItem = styled.div`
  border-bottom: 1px solid var(--border-grey);
  padding: 1.6rem 0;
`;

const SignOutButton = styled(SecondaryButton)`
  border: none;
`;
