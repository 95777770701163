import styled from 'styled-components';
import chevronRight from '../assets/chevronRight.svg';

import { useQuery } from "@tanstack/react-query";
import { PrimaryButton } from "../components/Button";
import { FlexColumn, FlexRow } from "../components/Layout";
import { PageContainer } from "../components/PageContainer";
import { Address, PatientInfo } from "../sharedTypes";
import { client } from "../api";
import { Link, useParams } from "react-router-dom";
import { MapStatic } from '../components/MapStatic';

type PatientAddressResponse = {
    patient_address: Address,
}

type PatientInfoResponse = {
    patient_info: PatientInfo
}

type CarepointTransferredRxResponse = {
    carepoint_claim_info: {
        cost: string
    };
    drug: string;
    drug_name: string;
}

const PatientCarepointTransferredRXInfo = () => {
    const params = useParams();
    const rxId = params.id

    const { data, isLoading } = useQuery(['rxCarepointTransferredRx'], async () => {
        return client.get(`rx/${rxId}/carepoint-transferred-rx`).then((res) => res.data as CarepointTransferredRxResponse);
    });

    if (isLoading || !data) {
        return (<>Prescription</>)
    }

    const cost = Number(data.carepoint_claim_info.cost).toLocaleString("en-US", { style: "currency", currency: "USD" })

    return (<>
        Prescription
        <div>
            <DrugNameRow>
                <div>
                    {data.drug} · {data.drug_name}
                </div>
                <div>
                    {cost}
                </div>
            </DrugNameRow>
            <DrugNameDeliveryInfo>
                Includes delivery fee
            </DrugNameDeliveryInfo>
        </div>
    </>)
}

const formatAddress = (address: Address) => {
    const { line1, line2, city, state, postal_code } = address;
    const shortAddress = `${line1} ${line2 || ''}`.trim();
    const locale = `${city}, ${state.toUpperCase()} ${postal_code}`;
    const fullAddress = `${shortAddress}, ${locale}`;

    return {
        shortAddress,
        locale,
        fullAddress,
    };
};

const PatientAddressInfo = ({ address }: { address: Address }) => {
    const { shortAddress, locale, fullAddress } = formatAddress(address)

    return (
        <PatientAddressInfoRow>
            <FlexColumn>
                <PatientAddressMapContainer>
                    <MapStatic center={`${fullAddress}`}></MapStatic>
                </PatientAddressMapContainer>
            </FlexColumn>
            <FlexColumn $spacing="none" $centerAlign $fullWidth role="button">
                <PatientAddressInfoInnerRow>
                    <PatientAddress>
                        <div>{shortAddress}</div>
                        <div>{locale}</div>
                    </PatientAddress>
                </PatientAddressInfoInnerRow>
            </FlexColumn>
        </PatientAddressInfoRow>
    )
}

const PatientDeliveryAddress = () => {
    const params = useParams();
    const rxId = params.id

    const { data } = useQuery<PatientAddressResponse>(['rxAddress', rxId], async () => {
        return client.get(`rx/${rxId}/address`).then((res) => res.data);
    });

    return (
        <>
            <CarepointAddressSubtitleRow $centerAlign>
                Delivery address
                {data && <Link to={`/rx/${rxId}/set-carepoint-delivery-address`} state={{ source: '/rx' }} >
                    {"Edit"} <PharmacyRightChevron src={chevronRight} alt="Pharmacy menu" />
                </Link>}
            </CarepointAddressSubtitleRow>
            {data && <PatientAddressInfo address={data.patient_address} />}
        </>
    )
}

const PatientCarepointConsent = () => {
    const { data, isLoading } = useQuery<PatientInfoResponse>(['rxPatient'], async () => {
        return client.get(`rx/patient`).then((res) => res.data);
    });

    if (isLoading || !data) {
        return (<>Loading...</>)
    }

    return (
        <CarepointAgreement>
            By signing electronically below, I, {data.patient_info.patient_name.first_name} {data.patient_info.patient_name.last_name} authorize Carepoint Pharmacy to coordinate with my medical provider and any applicable insurance and I attest that I request Carepoint to process and ship this medication and any subsequent refills, including automatic refills that I may enroll in.
        </CarepointAgreement>)
}

const DeliveryViaCarepoint = () => {
    return (
        <PageContainer
            pageType="modal"
            title="Deliver via Carepoint"
            footer={
                <PrimaryButton $fullWidth onClick={() => console.log("next")}>Next</PrimaryButton>
            }
        >
            <CarepointDeliveryItem>
                <FlexColumn>
                    <PatientCarepointTransferredRXInfo />
                </FlexColumn>
            </CarepointDeliveryItem>

            <CarepointDeliveryItem>
                <FlexColumn>
                    <PatientDeliveryAddress />
                </FlexColumn>
            </CarepointDeliveryItem>

            <CarepointDeliveryItem>
                <FlexColumn>
                    <PatientCarepointConsent />
                </FlexColumn>
            </CarepointDeliveryItem>
        </PageContainer>
    )
}

export default DeliveryViaCarepoint;


const PatientAddressInfoRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 0.8rem;
`;

const PatientAddressInfoInnerRow = styled(FlexRow)`
  justify-content: space-between;
`;

const PatientAddressMapContainer = styled.div`
  width: 72px;
  height: 72px;
`;

const PatientAddress = styled.div.attrs({ role: 'group' })`
  font-weight: 500;
  color: var(--black);
  
  div:last-child {
    color: var(--purple-dark);
    font-weight: 400;
  }
`;

const CarepointDeliveryItem = styled.div`
  border-bottom: 1px solid var(--border-grey);
  padding: 1.6rem 0;
`;

const CarepointAddressSubtitleRow = styled(FlexRow)`
  justify-content: space-between;
  margin-bottom: 1rem;

  & [class*='RxCardSubtitle'] {
    margin-bottom: 0;
  }
`;

const PharmacyRightChevron = styled.img`
  height: 0.66rem;
  margin-top: 0.33rem;
`;


const CarepointAgreement = styled.div`
  font-weight: 400;
  color: var(--purple-dark);
`;

const DrugNameDeliveryInfo = styled.div`
  color: var(--purple-dark);
  font-weight: 400;
`

const DrugNameRow = styled(FlexRow)`
  font-weight: 400;
`
