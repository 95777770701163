import { FormLabel } from '@mui/material';
import { Control, Controller, FormState, UseFormHandleSubmit } from 'react-hook-form';
import { PrimaryButton } from './Button';
import { FlexColumn } from './Layout';
import { UseMutationResult } from '@tanstack/react-query';
import React from 'react';
import { AxiosError } from 'axios';
import { RegisterTokenResponse } from '../pages/ClaimProfile';
import { PatientPortalErrorResponse } from '../sharedTypes';
import { Input, StyledDateField } from './Input';

type IdentityFormData = { dob: moment.Moment; last_name: string };
type VerifyIdentityProps = {
  handleSubmit: UseFormHandleSubmit<IdentityFormData>;
  control: Control<IdentityFormData>;
  formState: FormState<IdentityFormData>;
  submit: UseMutationResult<RegisterTokenResponse, AxiosError<PatientPortalErrorResponse>, IdentityFormData>;
};

export const VerifyIdentity: React.FC<VerifyIdentityProps> = ({ handleSubmit, control, formState, submit }) => {
  return (
    <form
      onSubmit={handleSubmit((data: IdentityFormData) => {
        submit.mutate(data);
      })}
    >
      {/* TODO share a component for this with ClaimProfile */}
      <FlexColumn $spacing="loose">
        <div>
          <Controller
            control={control}
            defaultValue=""
            name="last_name"
            rules={{ required: true }}
            render={({ field }) => <Input label="Last Name" {...field} />}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="dob"
            rules={{ required: true }}
            render={({ field }) => (
              <FormLabel>
                Date of Birth
                <StyledDateField sx={{ width: 1 }} {...field} />
              </FormLabel>
            )}
          />
        </div>
        <div>
          <PrimaryButton type="submit" $fullWidth disabled={!formState.isValid}>
            Continue
          </PrimaryButton>
        </div>
      </FlexColumn>
    </form>
  );
};
