import React from 'react';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 1.33rem;
  background-color: var(--white);
  border: 1px solid var(--border-grey);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const BaseClickableCard = styled(Card)<{ $expanded: boolean }>`
  box-shadow: ${({ $expanded }) => ($expanded ? '0px 2px 8px 0px rgba(0,0,0,0.1)' : 'none')};
  margin: ${({ $expanded }) => ($expanded ? '0 -0.8rem' : '0')};
  margin-top: 8px;
  cursor: pointer;

  div {
    pointer-events: none;
  }

  a,
  button,
  [role='button'],
  details,
  summary {
    pointer-events: auto;
  }
`;

export const CardExpandedContent = styled.div<{ $expanded: boolean }>`
  visibility: ${({ $expanded }) => ($expanded ? 'visible' : 'hidden')};
  max-height: ${({ $expanded }) => ($expanded ? '100vh' : '0')};
  overflow: hidden;
  transition: all 0.25s;
`;

export const CardSection = styled.div<{ $noBorder?: boolean }>`
  padding: 1.33rem;
  ${({ $noBorder }) => ($noBorder ? '' : 'border-top: 1px solid var(--border-grey);')}
`;

interface ClickableCardProps extends React.InputHTMLAttributes<HTMLInputElement> {
  $expanded: boolean;
  onClick: () => void;
}

export const ClickableCard: React.FC<ClickableCardProps> = (props) => {
  const { children, onClick, $expanded, role, ...rest } = props;

  return (
    <BaseClickableCard
      role={role || 'button'}
      $expanded={$expanded}
      aria-expanded={$expanded}
      tabIndex={0}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClick();
        }
      }}
      onKeyDown={(e) => {
        if (e && e.target === e.currentTarget && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault();
          onClick();
        }
      }}
      {...rest}
    >
      {children}
    </BaseClickableCard>
  );
};
