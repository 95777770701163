import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ScreenReaderOnly } from './ScreenReaderOnly';
import { BaseButtonMixin } from './Button';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * Renders a link that opens in a new tab or window,
 * with accessibility / security features included.
 */
export const OutboundLink = (props: Props) => {
  const { children, href, ...rest } = props;

  return (
    <StyledLink href={href} target="_blank" rel="noopener" {...rest}>
      {children}
      <ScreenReaderOnly>(opens in a new tab)</ScreenReaderOnly>
    </StyledLink>
  );
};

export const StyledLink = styled.a`
  color: var(--black);
  text-decoration: none;
  white-space: pre-wrap;
  cursor: pointer;
`;

export const StyledLinkBlock = styled(Link)`
  ${BaseButtonMixin}
  display: block;
  text-align: center;
  text-decoration: none;
`;

export const StyledLinkPrimary = styled(StyledLinkBlock)`
  background: var(--button-background);
  cursor: pointer;

  &:hover,
  &:active {
    box-shadow: inset 0 0 0 2px var(--border-grey);
    background-color: var(--white);
  }
`;
