import styled from 'styled-components';
import { FlexRow } from './Layout';
import { StyledLinkBlock } from './StyledLink';
import { LinkProps } from 'react-router-dom';

const IconItem = styled(FlexRow)`
  justify-content: flex-start;
  gap: 0.8rem;
  align-items: center;

  img {
    width: 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
  }
`;

interface IconListItemProps {
  iconSrc: string;
  text: React.ReactNode;
}

export const IconListItemLink: React.FC<IconListItemProps & LinkProps & React.RefAttributes<HTMLAnchorElement>> = (
  props,
) => {
  const { iconSrc, text, ...rest } = props;
  return (
    <ListItemLink {...rest}>
      <IconItem>
        <img src={iconSrc} alt="" /> {text}
      </IconItem>
    </ListItemLink>
  );
};

const ListItemLink = styled(StyledLinkBlock)`
  text-align: left;
  padding: 0;
  font-weight: 500;
  font-size: 1rem;

  &:not(:last-of-type) {
    margin-bottom: 2.13rem;
  }
`;
