import styled from 'styled-components';

/**
 * This component is used to hide text from the screen, but still make it available to screen readers.
 */
export const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
`;
