import type { AxiosError } from 'axios';
import { client } from './api';
import React, { PropsWithChildren, useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { Navigate, useParams } from 'react-router-dom';

import { FourOhFour } from './pages/404';
import { PharmacyLaunchPoint, InsuranceLaunchPoint } from './pages/LoggedOutLaunchPoint';
import { TokenExpired } from './pages/TokenExpired';
import { ClaimProfile } from './pages/ClaimProfile';
import { PatientPortalErrorResponse } from './sharedTypes';

type AuthLevel = 'full' | 'token' | 'none';
type TokenRedirect = 'pharmacy' | 'insurance' | 'sign_up';
type TokenData = {
  id: string;
  redirect?: TokenRedirect;
  expired?: boolean;
};
type User = {
  activeId?: string;
  token?: TokenData;
};
type AuthContextType = {
  auth: AuthLevel;
  loaded: boolean;
  user?: User;
};

export const AuthContext = React.createContext<AuthContextType>({
  auth: 'none',
  loaded: false,
  user: undefined,
});

export const LoginProvider = (props: { children: React.ReactNode }) => {
  const { isLoading, isError, data } = useQuery(
    ['checkLogin'],
    () =>
      client
        .get('info', {
          withCredentials: true,
        })
        .then((res) => res.data),
    { retry: false },
  );

  if (isLoading) {
    return <div />;
  }

  let value: AuthContextType;

  if (isError) {
    value = { auth: 'none', user: undefined, loaded: true };
  } else {
    const { auth_level, patient_id, token } = data;
    value = { auth: auth_level, user: { activeId: patient_id, token: token }, loaded: true };
  }

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export const LoginRequired = (
  props: PropsWithChildren<{ authLevelRequired?: AuthLevel; tokenValidation?: TokenRedirect }>,
) => {
  const { authLevelRequired = 'full', tokenValidation } = props;
  const authContext = React.useContext(AuthContext);

  if (authLevelRequired === 'full' && authContext.auth !== 'full') {
    return <Navigate to="/login" />;
  }

  if (authLevelRequired === 'token' && authContext.auth !== 'full') {
    if (authContext.auth === 'none' || (tokenValidation && authContext.user?.token?.redirect !== tokenValidation)) {
      return <FourOhFour />;
    }
  }

  return <>{props.children}</>;
};

export const LoginWithToken = () => {
  const { token } = useParams<{ token: string }>();
  const queryClient = useQueryClient();

  const checkToken = useMutation<TokenData, AxiosError<TokenData | PatientPortalErrorResponse>>({
    mutationFn: async () => {
      return client.post(`/check/${token}`).then((res) => res.data as TokenData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
    },
    retry: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps -- only run this once, checkToken will not change
  useEffect(() => checkToken.mutate(), []);

  if (checkToken.isIdle || checkToken.isLoading) {
    return <div />;
  }
  if (checkToken.isError) {
    const error = checkToken.error;
    if (error && error.response?.data && 'expired' in error.response.data) {
      if (error.response.data.redirect === 'sign_up') {
        return <Navigate to="/login" />;
      }
      return <TokenExpired token={error.response.data.id} />;
    } else {
      return <FourOhFour />;
    }
  }

  const target = checkToken.data.redirect || 'sign_up';

  switch (target) {
    case 'sign_up':
      return <ClaimProfile />;
    case 'pharmacy':
      return <PharmacyLaunchPoint />;
    case 'insurance':
      return <InsuranceLaunchPoint />;
    default:
      return <FourOhFour />;
  }
};
