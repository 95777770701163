import { Pharmacy } from '../sharedTypes';
import { formatPharmacyAddress } from '../utils';
import { PharmacyAddress } from './PharmacyAddress';
import { MapStatic } from './MapStatic';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from './Layout';

export const PharmacyInfo: React.FC<{ pharmacy: Pharmacy; onClick: () => void }> = ({ pharmacy, onClick }) => {
  const { shortAddress, locale, fullAddress } = formatPharmacyAddress(pharmacy);

  return (
    <>
      <RxPharmacyInfoRow>
        <FlexColumn>
          <RxCardMapContainer>
            <MapStatic center={`${pharmacy.name} ${fullAddress}`}></MapStatic>
          </RxCardMapContainer>
        </FlexColumn>
        <FlexColumn $spacing="none" $centerAlign $fullWidth role="button" onClick={onClick}>
          <RxPharmacyInnerRow>
            <PharmacyAddress>
              <div>{pharmacy.name}</div>
              <div>{shortAddress}</div>
              <div>{locale}</div>
            </PharmacyAddress>
          </RxPharmacyInnerRow>
        </FlexColumn>
      </RxPharmacyInfoRow>
    </>
  );
};

const RxPharmacyInfoRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 0.8rem;
`;

const RxPharmacyInnerRow = styled(FlexRow)`
  justify-content: space-between;
`;

const RxCardMapContainer = styled.div`
  width: 72px;
  height: 72px;
`;
