import { useContext } from 'react';
import { AuthContext } from '../auth';
import { Navigate } from 'react-router-dom';

export const Home = () => {
  const authContext = useContext(AuthContext);

  if (!authContext.loaded) {
    return <div />;
  }

  if (authContext.auth === 'full') {
    return <Navigate to="/rx" />;
  }

  return <Navigate to="/login" />;
};
