import help from '../assets/help.svg';
import pencil from '../assets/pencil.svg';
import call from '../assets/call.svg';
import directions from '../assets/directions.svg';

import { TANDEM_PHONE_NUMBER } from '../constants';
import { Rx, Pharmacy } from '../sharedTypes';
import { formatPharmacyAddress, formatPhoneNumber, getGoogleMapsUrl, phoneNumberHref } from '../utils';
import { FlexColumn, FlexRow } from './Layout';
import { MapStatic } from './MapStatic';
import { ModalSheet } from './ModalSheet';
import { PharmacyAddress } from './PharmacyAddress';
import styled from 'styled-components';
import { LabelValue } from './LabelValue';
import moment from 'moment';
import { IconListItemLink } from './IconListItemLink';

interface RxPharmacyModalProps {
  modalRx: Rx;
  modalPharmacy: Pharmacy;
  onClose: () => void;
}

export const RxPharmacyModal: React.FC<RxPharmacyModalProps> = ({ modalRx, modalPharmacy, onClose }) => {
  const showUpdatePharmacy = (rx: Rx | null, pharmacy: Pharmacy | null): boolean => {
    return rx !== null && rx.status !== 'Canceled' && pharmacy !== null && rx.transfer_status === 'Pending';
  };

  const formattedPharmacyAddress = formatPharmacyAddress(modalPharmacy);

  return (
    <ModalSheet $open={modalPharmacy !== null} onClose={onClose}>
      {modalRx && modalPharmacy && formattedPharmacyAddress ? (
        <>
          <PharmacyModalInfoRow>
            <FlexColumn $spacing="none">
              <PharmacyModalMapContainer>
                <MapStatic
                  height={44}
                  width={44}
                  pinSize="tiny"
                  center={`${modalPharmacy.name} ${formattedPharmacyAddress.fullAddress}`}
                ></MapStatic>
              </PharmacyModalMapContainer>
            </FlexColumn>
            <FlexColumn $spacing="none" $centerAlign>
              <FlexRow>
                <PharmacyAddress>
                  <div>{modalPharmacy.name}</div>
                  <div>{formattedPharmacyAddress.fullAddress}</div>
                </PharmacyAddress>
              </FlexRow>
            </FlexColumn>
          </PharmacyModalInfoRow>
          {modalRx.drug_refills || modalRx.sent_date ? (
            <PharmacyModalInfoRow>
              <PharmacyModalLabelValueContainer $spacing="none">
                {modalRx.drug_refills ? (
                  <PharmacyModalLabelValue label="Refills" value={`${modalRx.drug_refills} left`} />
                ) : null}
                {modalRx.sent_date ? (
                  <PharmacyModalLabelValue label="Sent" value={moment(modalRx.sent_date).format('MMM D, YYYY')} />
                ) : null}
              </PharmacyModalLabelValueContainer>
            </PharmacyModalInfoRow>
          ) : null}
          <PharmacyModalInfoRow>
            <FlexColumn $spacing="none">
              <IconListItemLink
                iconSrc={call}
                text={`Call ${formatPhoneNumber(modalPharmacy.phone_number)}`}
                to={phoneNumberHref(modalPharmacy.phone_number)}
              ></IconListItemLink>
              <IconListItemLink
                iconSrc={directions}
                text="Get directions"
                to={getGoogleMapsUrl(`${modalPharmacy.name} ${formattedPharmacyAddress.fullAddress}`)}
                target="_blank"
              />
              {showUpdatePharmacy(modalRx, modalPharmacy) ? (
                <IconListItemLink
                  iconSrc={pencil}
                  text="Change pharmacy"
                  to={`/rx/${modalRx.id}/set-pharmacy`}
                  state={{ source: '/rx' }}
                />
              ) : null}
              <IconListItemLink
                iconSrc={help}
                text="Get help"
                to={`sms:${TANDEM_PHONE_NUMBER}&body=${encodeURIComponent(
                  `Hi. I need help with my prescription for ${modalRx.drug_name}.`,
                )}`}
              />
            </FlexColumn>
          </PharmacyModalInfoRow>
        </>
      ) : null}
    </ModalSheet>
  );
};

const PharmacyModalMapContainer = styled.div`
  width: 44px;
  height: 44px;
  border-radius 0.53rem;
`;

const PharmacyModalInfoRow = styled(FlexRow)`
  justify-content: flex-start;
  gap: 0.8rem;
  padding: 1.33rem;

  &:not(:first-child) {
    padding-top: 1.33rem;
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--border-grey);
  }
`;

const PharmacyModalLabelValueContainer = styled(FlexColumn)`
  width: 100%;
  margin: 0;
`;

const PharmacyModalLabelValue = styled(LabelValue)`
  div:first-child {
    font-weight: 500;
  }
`;
